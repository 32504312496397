import { Component, Input } from '@angular/core';
import { NavItem } from '../../../../modules/constants/nav-item';
import { Router } from '@angular/router';
import { UiUtilityService } from '../../../../../common/services/ui-utility/ui-utility.service';

@Component({
    selector: 'app-admin-menu-list',
    standalone: false,
    templateUrl: './admin-menu-list.component.html',
    styleUrl: './admin-menu-list.component.scss',
})
export class AdminMenuListComponent {
    @Input() item!: NavItem;

    constructor(private readonly router: Router, private readonly uiUtilityService: UiUtilityService) {}

    navigateWithFunction(event: Event, routeUrl: string | undefined) {
        event.preventDefault();
        this.router.navigate([routeUrl]);
    }

    isCurrentRoute(item: NavItem) {
        if (this.router.url === `/${item.route}`) {
            return true;
        } else if (item.children) {
            return item.children.some((child) => this.router.url === `/${child.route}`);
        } else {
            return false;
        }
    }
}
