import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AdminNavService } from '../admin-nav.service';
import { NavItem } from '../../../modules/constants/nav-item';
import { AdminAuthService } from '../../../modules/services/admin-auth.service';
import { navigationRoutes } from '../../../../common/routes/navigation/navigation-routes';
import { adminNavItems } from '../../../modules/menu-items.ts/admin';

@Component({
    selector: 'app-admin-main-layout',
    standalone: false,
    templateUrl: './admin-main-layout.component.html',
    styleUrl: './admin-main-layout.component.scss',
})
export class AdminMainLayoutComponent implements OnInit {
    showDashboard = false;
    name: string | undefined;
    userType: string;
    navNewItems: NavItem[] = [];
    isSidenavOpen = true;
    isLoggedInCheck: boolean;

    @ViewChild('sidenav') sidenav!: MatSidenav;

    constructor(
        public readonly router: Router,
        public readonly adminNavService: AdminNavService,
        private readonly adminAuthService: AdminAuthService
    ) {
        this.name = this.adminAuthService.getUserName();
        this.userType = sessionStorage.getItem('userType') ?? '';
        this.isLoggedInCheck = this.adminAuthService.isAuthenticated();
        this.showDashboard = this.isLoggedInCheck;
        this.navNewItems = this.filterNavItems(adminNavItems);
    }

    ngOnInit(): void {
        this.adminNavService.showDashboard.subscribe((res) => {
            this.showDashboard = res;
        });
        this.name = this.adminAuthService.getUserName();
    }

    logout(): void {
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate([navigationRoutes.navigateToAdminLogin]).then(() => {
            window.location.reload();
        });
    }

    sidenavToggle() {
        this.isSidenavOpen = !this.isSidenavOpen;
    }

    private filterNavItems(navItems: NavItem[]): NavItem[] {
        return navItems.map((item) => this.processNavItem(item)).filter((item) => item.show);
    }

    private processNavItem(item: NavItem): NavItem {
        const newItem = { ...item };

        if (newItem.children && newItem.children.length > 0) {
            newItem.children = newItem.children.map((child) => this.processNavItem(child)).filter((child) => child.show);
            newItem.show = newItem.children.length > 0;
        } else {
            newItem.show = this.shouldShowItem(newItem);
        }
        return newItem;
    }

    private shouldShowItem(item: NavItem): boolean {
        return (
            this.adminAuthService.isModuleAssigned(item?.name) ||
            (item?.name === 'accountingPortal' && this.adminAuthService.isProjectEnabled(item?.name)) ||
            (item?.name === 'deliveryPartners' && this.adminAuthService.isProjectEnabled(item?.name))
        );
    }
}
