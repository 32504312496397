import { Component, EventEmitter, Input, Output } from '@angular/core';
import { navigationRoutes } from '../../../../../common/routes/navigation/navigation-routes';
import { Router } from '@angular/router';
import { UiUtilityService } from '../../../../../common/services/ui-utility/ui-utility.service';

@Component({
    selector: 'app-admin-header',
    standalone: false,
    templateUrl: './admin-header.component.html',
    styleUrl: './admin-header.component.scss',
})
export class AdminHeaderComponent {
    @Input() public loggedIn: string | undefined;
    @Input() public isLoggedInCheck: boolean | undefined;
    @Output() sidenavToggle = new EventEmitter<void>();

    navigationRoutes = navigationRoutes;

    constructor(private readonly router: Router, private readonly uiUtilityService: UiUtilityService) {}

    logout() {
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(['/admin/login']).then(() => {
            window.location.reload();
        });
    }

    onSidenavToggle() {
        this.sidenavToggle.emit();
    }

    accountInfo(): void {
        if (this.loggedIn) {
            this.router.navigate([navigationRoutes.navigateToAdminHome]);
        } else {
            this.router.navigate([navigationRoutes.navigateToAdminLogin]);
        }
    }
}
