import { NavItem } from '../constants/nav-item';

export const adminNavItems: NavItem[] = [
    {
        iconName: 'fa-tachometer-alt',
        route: 'dashboard',
        role: 'ADMIN',
        name: 'apiDashboard',
        translateName: 'dashboard',
        children: [
            {
                iconName: 'fa-chart-simple',
                route: 'admin/main/dashboard',
                role: 'ADMIN',
                name: 'apiLogs',
                translateName: 'apiLogs',
            },
            {
                iconName: 'fa-signal',
                route: 'admin/main/dashboard/notification-logs',
                role: 'ADMIN',
                name: 'notificationLogs',
                translateName: 'notificationLogs',
            },
        ],
    },
    {
        iconName: 'fa-user-circle',
        route: 'user-management',
        role: 'ADMIN',
        name: 'userManagement',
        translateName: 'usermanagement',
        children: [
            {
                iconName: 'fa-circle',
                route: 'admin/main/user-management/registered-portal-user-management',
                role: 'ADMIN',
                name: 'moovetUsers',
                translateName: 'moovetusers',
            },
        ],
    },
    {
        iconName: 'fa-file-excel',
        route: 'reports',
        role: 'ADMIN',
        name: 'reports',
        translateName: 'reports',
        children: [
            {
                iconName: 'fa-circle',
                route: 'admin/main/reports/user-response/list',
                role: 'ADMIN',
                name: 'userResponses',
                translateName: 'userResponses',
            },
            {
              iconName: 'fa-circle',
              route: 'admin/main/reports/user-response/insights',
              role: 'ADMIN',
              name: 'userResponseInsights',
              translateName: 'userResponseInsights',
          },
        ],
    },
];
