<div class="sidebar-menu-list">
  <ng-container *ngIf="item.children && item.children.length > 0; else other_content">
      <mat-expansion-panel [class.mat-elevation-z0]="true" [expanded]="isCurrentRoute(item)">
          <mat-expansion-panel-header>
              <mat-icon fontSet="fas" [fontIcon]="item.iconName"></mat-icon>
              {{ 'menulist.' + item.translateName | translate }}
          </mat-expansion-panel-header>
          <ng-container *ngFor="let child of item.children">
              <ng-container *ngIf="child.children && child.children.length > 0">
                  <mat-expansion-panel [class.mat-elevation-z0]="true" [expanded]="isCurrentRoute(child)">
                      <mat-expansion-panel-header>
                          <mat-icon fontSet="fas" [fontIcon]="child.iconName"></mat-icon>
                          {{ 'menulist.' + child.translateName | translate }}
                      </mat-expansion-panel-header>
                      <ng-container *ngFor="let innerchild of child.children">
                          <div class="inner-child">
                              <ng-container *ngIf="innerchild.show">
                                  <a href="{{ innerchild.route }}" (click)="navigateWithFunction($event, innerchild.route)">
                                      <mat-list-item>
                                          <mat-icon fontSet="fas" [fontIcon]="innerchild.iconName"></mat-icon>
                                          {{ 'menulist.' + innerchild.translateName | translate }}
                                      </mat-list-item>
                                  </a>
                              </ng-container>
                          </div>
                      </ng-container>
                  </mat-expansion-panel>
              </ng-container>
              <ng-container *ngIf="!child.children">
                  <ng-container *ngIf="child.show">
                      <a href="{{ child.route }}" (click)="navigateWithFunction($event, child.route)">
                          <mat-list-item>
                              <mat-icon fontSet="fas" [fontIcon]="child.iconName"></mat-icon>
                              {{ 'menulist.' + child.translateName | translate }}
                          </mat-list-item>
                      </a>
                  </ng-container>
              </ng-container>
          </ng-container>
      </mat-expansion-panel>
  </ng-container>
  <ng-template #other_content>
      <a href="{{ item.route }}" (click)="navigateWithFunction($event, item.route)">
          <mat-list-item>
              <mat-icon fontSet="fas" [fontIcon]="item.iconName"></mat-icon>
              {{ 'menulist.' + item.translateName | translate }}
          </mat-list-item>
      </a>
  </ng-template>
</div>
