import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AdminNavService {
    public currentUrl = new BehaviorSubject<string>('');
    public showDashboard = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                console.log('NavigationEnd event:', event);

                let activeRoute = this.activatedRoute;

                // ✅ Traverse all child routes to find the last activated route
                while (activeRoute.firstChild) {
                    activeRoute = activeRoute.firstChild;
                }

                console.log('Final ActivatedRoute Snapshot:', activeRoute.snapshot);
                console.log('showDashboard from route data:', activeRoute.snapshot.data['showDashboard']);

                this.currentUrl.next(event.urlAfterRedirects);

                // ✅ Ensure the route data is properly read
                if (activeRoute.snapshot.data && activeRoute.snapshot.data['showDashboard'] !== undefined) {
                    this.showDashboard.next(activeRoute.snapshot.data['showDashboard']);
                } else {
                    console.warn('showDashboard not found in active route data');
                }
            }
        });
    }
}
