import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminMainLayoutComponent } from './admin-main-layout.component';
import { adminAuthGuard } from '../../../modules/guards/admin-auth.guard';
import { PermissionGuardService } from '../../../modules/guards/permission-guard.service';

const routes: Routes = [
    {
        path: '',
        component: AdminMainLayoutComponent,
        canActivate: [adminAuthGuard],
        children: [
            {
                path: 'home',
                loadChildren: () => import('../../admin-home/admin-home.module').then((m) => m.AdminHomeModule),
                data: { showDashboard: true },
                title: 'Admin | Checking Permissions',
            },
            {
                path: 'dashboard',
                loadChildren: () => import('../../dashboard/dashboard.module').then((m) => m.DashboardModule),
                data: { showDashboard: true },
                canActivate: [PermissionGuardService],
                title: 'Dashboard | Admin',
            },
            {
                path: 'reports',
                loadChildren: () => import('../../reports/reports.module').then((m) => m.ReportsModule),
                data: { showDashboard: true, expectedPermission: 'userResponses' },
                canActivate: [PermissionGuardService],
                title: 'Reports | Admin',
            },
            {
                path: 'user-management/registered-portal-user-management',
                loadChildren: () =>
                    import('../../user-management/registered-portal-user-management/registered-portal-user-management.module').then(
                        (m) => m.RegisteredPortalUserManagementModule
                    ),
                data: { showDashboard: true, expectedPermission: 'moovetUsers' },
                canActivate: [PermissionGuardService],
                title: 'User Management | Moovet Admin',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminMainLayoutRoutingModule {}
