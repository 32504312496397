import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminMainLayoutRoutingModule } from './admin-main-layout-routing.module';
import { AdminMainLayoutComponent } from './admin-main-layout.component';
import { MaterialModule } from '../../../../common/modules/material/material.module';
import { AdminMenuListComponent } from './admin-menu-list/admin-menu-list.component';
import { DependencyModule } from '../../../../common/modules/dependency/dependency.module';
import { TranslatorModule } from '../../../../common/modules/translator/translator.module';
import { AdminHeaderComponent } from './admin-header/admin-header.component';

@NgModule({
    declarations: [AdminMainLayoutComponent, AdminMenuListComponent, AdminHeaderComponent],
    imports: [CommonModule, AdminMainLayoutRoutingModule, MaterialModule, DependencyModule, TranslatorModule],
})
export class AdminMainLayoutModule {}
