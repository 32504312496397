import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { navigationRoutes } from '../../../common/routes/navigation/navigation-routes';
import { AdminAuthService } from '../services/admin-auth.service';


export const adminAuthGuard: CanActivateFn = (route, state) => {
  const adminAuthService = inject(AdminAuthService);

    const router = inject(Router);

    if (!adminAuthService.isAuthenticated()) {
        router.navigate([navigationRoutes.navigateToAdminLogin], {
            queryParams: { returnUrl: state.url },
        });

        return false;
    }

    return true;
};

export const adminLoggedInGuard: CanActivateFn = () => {
    const adminAuthService = inject(AdminAuthService);
    const router = inject(Router);
    const otpPurpose = sessionStorage.getItem('otp-purpose');

    if (adminAuthService.isAuthenticated() && otpPurpose !== 'resetPassword') {
        router.navigate([navigationRoutes.navigateToAdminHome]);
        return false;
    }

    return true;
};
