<ng-container *ngIf="showDashboard">
  <app-admin-header [loggedIn]="name" [isLoggedInCheck]="isLoggedInCheck" (sidenavToggle)="sidenavToggle()"></app-admin-header>

  <mat-sidenav-container class="sidenav-container" autosize>
      <mat-sidenav
          #sidenav
          mode="side"
          [opened]="isSidenavOpen"
          class="sidenav"
          [fixedInViewport]="true"
          [fixedTopGap]="90"
          [fixedBottomGap]="0"
      >
          <mat-nav-list>
              <ng-container *ngFor="let item of navNewItems">
                  <app-admin-menu-list *ngIf="item.show" [item]="item"></app-admin-menu-list>
              </ng-container>
              <mat-list-item class="sidebar-menu-list" (click)="logout()" (keyup)="logout()">
                  <mat-icon class="logout-icon" fontSet="fas" fontIcon="fa-sign-out-alt"></mat-icon>
                  <span class="logout-button-text">{{ 'header.logout' | translate }}</span>
              </mat-list-item>
          </mat-nav-list>
      </mat-sidenav>

      <mat-sidenav-content>
          <router-outlet></router-outlet>
      </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<ng-container *ngIf="!showDashboard">
  <router-outlet></router-outlet>
</ng-container>
