<mat-toolbar class="header">
  <button class="mobile-view-menu" mat-icon-button (click)="onSidenavToggle()">
      <mat-icon class="menu-icon">menu</mat-icon>
  </button>
  <a class="navbar-brand ms-4">
      <img src="/assets/img/mastercard-logo.png" alt="Admin" width="auto" height="35" />  <span class="mc-ob-text ms-2"> {{ 'common.logoTitle' | translate }}</span>
  </a>
  <div class="ms-auto d-flex align-items-center">
      <button mat-icon-button aria-label="Account Info" class="me-2" (click)="accountInfo()">
          <mat-icon class="account-icon" style="font-size: 26px">account_circle</mat-icon>
      </button>
      <span class="welcome-title me-4">
          {{ 'header.welcome' | translate }},
          <span class="username-text">{{ loggedIn }}</span>
      </span>
  </div>
  <button class="logout-button" (click)="logout()">
      <mat-icon class="logout-icon" fontSet="fas" fontIcon="fa-sign-out-alt"></mat-icon>
      <span class="logout-button-text">{{ 'header.logout' | translate }}</span>
  </button>
</mat-toolbar>
